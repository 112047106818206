@import 'styles/variables'

.Summary
  margin-top: 4rem

  h2
    font-size: 2.2rem
    margin: 3rem 0 .5rem

    & + p
      font-size: 1.4rem
      margin-bottom: 4rem

  .part
    margin-bottom: 5rem

    &:last-child
      margin-bottom: 0

  :global
    .cart
      box-sizing: border-box

      h3
        font-size: 1.6rem
        margin: 0 0 2rem

      .total-before-discount
        display: block
        color: $gray-text
        font-size: 1.8rem
        margin-bottom: 1rem
        text-decoration: line-through

      .total
        display: block
        color: $black
        font-size: 2.4rem
        margin-bottom: 2rem

      .vat
        display: flex
        flex-direction: column
        font-size: 1.4rem

        & > *
          display: inline-block
          margin-bottom: .5rem

        strong
          color: $black
          margin-bottom: 2rem

      .info-block
        display: flex
        flex-direction: column
        margin-bottom: 2rem
        padding: 1rem

        &.orange
          background-color: $primary-orange

        &.green
          background-color: $primary-green

        &.blue
          background-color: $primary-blue

        & > *
          display: inline-block

        .small
          margin-bottom: 1rem
          font-size: 1.2rem

        strong
          color: $secondary-blue-dark
          font-size: 1.6rem

        &.orange
          strong
            color: $secondary-orange-dark

        &.green
          strong
            color: $secondary-green-dark

        &.blue
          strong
            color: $secondary-blue-dark

      .cart-items
        padding-left: 0
        list-style: none
        font-size: 1.4rem
        margin: 1rem 0

        .item
          display: flex
          flex-direction: row
          flex-wrap: wrap
          transition: margin-bottom .3s ease
          overflow: hidden
          margin-bottom: 2rem

          & > *
            display: inline-block
            margin-right: 1rem

          .title
            // margin-bottom: .5rem

          .price
            color: $black
            // font-size: 1.6rem

            .original-price
              display: inline-block
              margin-left: 1rem
              // font-size: 1.4rem
              color: $gray-text
              text-decoration: line-through

          .options
            width: 100%
            box-sizing: border-box
            padding-left: 2rem
            margin: 0
            list-style: none
            position: relative

            &:before
              content: ''
              display: inline-block
              position: absolute
              left: 0
              bottom: 0
              width: .5rem
              height: calc(100% - 2rem)
              background-color: $secondary-orange-light
              transition: background-color .15s ease

            .option
              display: flex
              flex-direction: row
              margin-top: 2rem
              margin-bottom: .5rem

              .option-title
                margin-right: 1rem

              .original-option-price
                // font-size: 1.4rem
                // margin-bottom: .5rem
                color: $gray-text
                text-decoration: line-through

              .option-price
                color: $black
                // font-size: 1.6rem

                .original-price
                  display: inline-block
                  margin-left: 1rem
                  // font-size: 1.4rem
                  color: $gray-text
                  text-decoration: line-through
