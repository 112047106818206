.container
  padding: 4rem
  // max-width: calc(100vw - 8rem)
  // padding: 0 1rem
  // margin: 3rem auto 6rem

// .header
//   display: flex
//   flex-direction: column
//   align-items: center
