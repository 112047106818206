@import '/styles/variables'

.checkcard
  position: relative
  display: flex
  flex-direction: column
  box-sizing: border-box
  // min-height: 9.3rem
  box-shadow: $secondary-card-shadow

  .disabled
    cursor: not-allowed

    &:after
      content: ''
      position: absolute
      display: block
      top: 0
      right: 0
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      background-color: transparent

    :global
      .inner
        background-color: $gray-background

        .checkbox input:not(:checked) ~ .checkmark
          background-color: $gray-background

        .right
          hr
            background: $gray-text

          strong
            color: $gray-text

        .content
          .title,
          strong
            color: $gray-text

    .highlighted
      border: 0.1rem solid $secondary-orange-dark

      :global
        .inner
          .right
            color: $secondary-orange-dark

            hr
              background: $secondary-orange-dark

            strong
              color: $secondary-orange-dark
  :global
    .inner
      display: flex
      box-sizing: border-box
      background-color: $white

      &.clickable
        cursor: pointer

      .checkbox
        margin-left: 2rem

      .right
        display: flex
        flex-direction: column
        justify-content: center
        margin-left: auto
        text-align: right
        padding: 2rem
        padding-left: 0

        & > *
          margin: 0 0 0.5rem 0
          white-space: nowrap

          &:last-child
            margin-bottom: 0

          &.line-through
            text-decoration: line-through

          &.gray-text
            color: $gray-text

        hr
          border: none
          background: $black
          width: 1.5rem
          height: 0.2rem

        strong
          color: $black
          font-size: 1.4rem

      .content
        display: flex
        flex-direction: column
        justify-content: center
        padding: 2rem

        & > *
          margin: 0 0 0.5rem 0

          &:last-child
            margin-bottom: 0

        p
          span.newline
            display: block
            margin: 0 0 0.5rem 0

            &:last-child
              margin-bottom: 0

        .title,
        strong
          color: $black
          font-size: 1.4rem

        .icon
          vertical-align: baseline
          margin-right: 0.5rem

    .expandable
      display: flex
      flex-direction: column
      background-color: $primary-orange
      padding: 2rem 2rem 2rem 6rem
      box-sizing: border-box

      & > *
        margin: 0 0 1rem 0

        &:last-child
          margin-bottom: 0

      p
        &.text-instruction
          margin: 0

          &.padding-bottom
            padding-bottom: 2rem

          .line
            display: block

            & + .line
              margin-top: 1rem

      strong
        font-size: 1.4rem

        &.normal-size
          font-size: inherit

      strong,
      label
        color: $black
        font-weight: bold

      p + label,
      span + label,
      input:not([type='checkbox']):not([type='radio']) + *
        margin-top: 2rem

      .input-group
        position: relative

        .iti + span.error-message
          position: absolute
          display: inline-block
          max-width: 100%
          top: 100%
          left: 0

        .address-map
          & + .selected-address
            display: block
            margin: 1rem 0
            font-size: 1.2rem
            font-weight: bold

            strong
              font-size: 1.2rem

      .grid
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 2rem
        align-items: end

        @media screen and (max-width: 800px)
          grid-template-columns: 1fr

        .input-group
          input,
          textarea,
          select,
          .react-datepicker-wrapper
            display: block
            width: 100%

        .full-width
          grid-column: 1 / span 2

          @media screen and (max-width: 800px)
            grid-column: 1

        .empty
          height: 0

          @media screen and (max-width: 800px)
            display: none
