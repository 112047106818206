@import 'styles/variables'

.Loading
  &.FullPage
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: $white

.LoadingContent
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: auto

  &.FullPage
    width: 100vw
    height: 100vh

  .spinner
    margin: 0 auto
