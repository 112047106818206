@import 'styles/variables'

.AddressMap
  .AutoComplete
    input
      width: 100%
      height: 4rem
      box-sizing: border-box
      padding: .8rem 1.5rem
      padding-left: 3rem
      border: 1px solid $black
      font-size: 1.4rem
      background-image: url('/images/icons/marker.svg')
      background-repeat: no-repeat
      background-size: auto 1.5rem
      background-position: 1rem center

      &:focus
        outline: none

      &:global(.error-input)
        border-color: $red

        &:hover,
        &:focus
          border-color: $red

    span:global(.error-message)
      display: inline-block
      font-size: 1.2rem
      font-weight: bold
      color: $red
      margin-top: .5rem
      margin-bottom: .5rem

  &:global(.right)
    *:global(.auto-complete)
      input
        padding-left: 1.5rem
        padding-right: 3rem
        background-position: right 1rem center
