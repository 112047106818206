@import 'styles/variables'

$order-faded-opacity: 0.1

.InstructionSelection
  margin-top: 5rem

  h2
    font-size: 2.2rem
    margin: 3rem 0 .5rem

    & + p
      font-size: 1.4rem
      margin-bottom: 4rem

  :global
    .input-group
      .iti
        display: block

    .center
      text-align: center

    .empty-result-cards
      max-width: 60rem

    .checkcard
      transition: margin-bottom .3s ease, opacity .3s ease

      & + h3
        transition: margin-top .3s ease, opacity .3s ease

      &.faded
        opacity: $order-faded-opacity !important

      .expandable
        label.checkbox .checkmark
          background-color: $white

        label.checkbox input:checked ~ .checkmark
          background-color: $secondary-orange-light

        .original-price
          display: inline-block
          margin-left: .75rem
          font-size: 1.2rem
          color: $gray-text

          .line-through
            text-decoration: line-through

    .button-row
      display: flex
      justify-content: space-between
      padding-top: 2rem
      margin-top: auto

      .button-wrap
        display: flex
        align-items: center

      .button
        flex-shrink: 0

      span
        margin: 0 2rem

        a
          color: $black
          transition: color .15s ease

          &:hover
            color: $secondary-orange-dark

        &.darker-warning
          color: $black
          font-weight: bold

    .spinner
      margin: auto

    .react-datepicker
      .react-datepicker__day
        span
          display: inline-block
          width: 2.6rem

          &.has-tooltip
            cursor: help

        .__react_component_tooltip
          cursor: auto
          padding: 1rem
          min-width: 30rem

          .tooltip-content
            display: inline-block

            .blockinfo
              display: inline-block
              font-weight: normal

              h4
                white-space: normal
                word-wrap: normal

        &.react-datepicker__day--disabled
          span
            cursor: not-allowed
            text-decoration: line-through

            &.has-tooltip
              cursor: help
              text-decoration: none

.typeHeading
  margin-top: 5rem

  &.red
    color: $red

.checkcard
  margin-bottom: 2rem
