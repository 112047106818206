@import 'styles/variables'

.AlertMessage
  &.FullPage
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: adjust-color($white, $alpha: -0.1)

  &.Opaque
    background-color: $white

.AlertMessageContent
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: auto
  font-size: 1.5em

  & > *
    margin: 1rem auto

  &.FullPage
    width: 100vw
    height: 100vh
    box-sizing: border-box
    padding: 0 2rem

  .Icon
    width: 4em
    height: auto
    color: $black
