@import 'styles/variables'

.blockinfo
  box-sizing: border-box
  border-radius: .2rem
  padding: 1rem 1.5rem
  color: $gray-text
  word-wrap: break-word
  overflow: hidden

  &.discreet
    &, *
      font-size: .9rem

  & > *
    margin: 0 auto .5rem

    &:last-child
      margin-bottom: 0

  h4
    color: $gray-text

  .icon
    width: 1em
    margin-right: .5em

  a
    transition: color .3s ease
    display: inline-block
    text-decoration: none
    color: $gray-text

    .icon
      transition: fill .3s ease

    &:hover
      cursor: pointer

  &.orange
    background-color: $primary-orange

    a:hover
      color: $secondary-orange-dark

      .icon
        fill: $secondary-orange-dark

  &.blue
    background-color: $primary-blue

    a:hover
      color: $secondary-blue-dark

      .icon
        fill: $secondary-blue-dark

  &.green
    background-color: $primary-green

    a:hover
      color: $secondary-green-dark

      .icon
        fill: $secondary-green-dark

  &.red
    background-color: $red-light

    a:hover
      color: $red

      .icon
        fill: $red
