@import 'styles/variables'

.ProductSelection
  margin-top: 4rem

  h2
    font-size: 2.2rem
    margin: 3rem 0 .5rem

    & + p
      font-size: 1.4rem
      margin-bottom: 4rem

.ProductSegment
  // 

.SegmentNumber,
.SegmentLabels
  display: flex
  justify-content: center
  height: 2rem

  .Red
    color: $red

.SegmentLabels
  justify-content: space-between

.ProductType
  .ProductTypeWrap
    display: flex
    flex-wrap: wrap
    gap: 4rem

    .ProductTypeIndividual
      // 

.typeHeading
  margin-top: 5rem

.checkcard
  margin-bottom: 2rem
