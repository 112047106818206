@import 'styles/variables'

.AddressSelection
  h2
    margin-top: 0

  .AddressMap
    margin: 2rem auto 0

  .SelectedAddress
    display: block
    margin: 2rem auto 0
    font-size: 1.6rem
    color: $black
