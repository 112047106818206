@import 'styles/variables'

.OrderPlacement
  padding-top: 2rem

  :global
    .agreement-warning
      display: block

    .message-block
      padding-top: 1rem

      a
        color: $black
        text-decoration: underline
        transition: color .15s ease

    .button-row
      display: flex
      justify-content: space-between
      padding-top: 2rem
      margin-top: auto

      .button-wrap
        display: flex
        align-items: center

      .button
        flex-shrink: 0

      span
        margin: 0 2rem

        a
          color: $black
          transition: color .15s ease

          &:hover
            color: $secondary-orange-dark

        &.darker-warning
          color: $black
          font-weight: bold
